import { createApp } from "vue";
import App from "@/App.vue";
import vuetify from "@/plugins/vuetify";
import "vue-toast-notification/dist/theme-sugar.css";
import "@ag-grid-community/styles/ag-grid.min.css";
import "@ag-grid-community/styles/ag-theme-balham.min.css";
import "v-calendar/style.css";
import "@/scss/main.scss";
// import { ENVIRONMENT } from "@/constants/envs";
// import * as Sentry from "@sentry/vue";
import pinia from "@/stores";
import router from "@/router";
import { setupCalendar } from "v-calendar";
import i18n from "@/plugins/vue-i18n";

import VueDOMPurifyHTML from "vue-dompurify-html";
import { pendingRequests } from "@/utils/apis";
import { CanceledError } from "axios";

const app = createApp(App);

// if (ENVIRONMENT !== "local") {
  // Sentry.init({
  //   app,
  //   environment: ENVIRONMENT,
  //   integrations: [
  //     Sentry.replayIntegration({
  //       maskAllText: false,
  //       blockAllMedia: false,
  //     }),
  //   ],
  //   replaysSessionSampleRate: 0,
  //   replaysOnErrorSampleRate: 1.0,
  // });
// }

router.beforeEach((to, from, next) => {
  pendingRequests.forEach((cancelToken, requestId) => {
    try {
      cancelToken.cancel(`Route change: ${from.path} to ${to.path}`);
    } catch (e: unknown) {
      if (e instanceof CanceledError) {
        // ignore
      } else {
        throw e;
      }
    }
    pendingRequests.delete(requestId);
  });
  // 동일한 경로를 클릭했을 때 `meta.refresh` 플래그 변경
  if (to.path === from.path) {
    to.meta.refresh = !to.meta.refresh; // 플래그 토글
  }
  next(); // 페이지 이동을 중단하지 않음
});

app
  .use(router)
  .use(i18n)
  .use(pinia)
  .use(vuetify)
  .use(VueDOMPurifyHTML)
  .use(setupCalendar, {})
  .mount("#app");
