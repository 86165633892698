export default {
  ko: {
    notice: {
      notice: "공지사항",
      pageTitle: "@:baord.notice.notice",
      noticeForAll: "전 지점 공지",
      noticeExpired: "팝업 마감",
    },
    qna: {
      qna: "Q & A",
      pageTitle: "@:baord.qna.qna",
      answer: "답변",
      qnaAnswer: "@:board.qna.qna @:board.qna.answer",
      previousWriting: "작성글",
      orderNo: "주문 번호",
      hawbNo: "운송장 번호",
      failRemoveBecauseChild: "답변이 있는 Q & A는 삭제할 수 없습니다.",
    },
  },
  en: {},
  zhHans: {},
};
