import { defineStore } from "pinia";
import type { AppConfig } from "@/definitions/types";
import { LangType } from "@/definitions/selections";

export const useConfigStore = defineStore("config", {
  state: (): AppConfig => {
    return {
      language: LangType.KR,
      station: window.localStorage.getItem("station") ?? "082",
      stationName: "SEL",
    };
  },
  persist: true,
});
