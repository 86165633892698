export default {
  ko: {
    deptDay: "출발일",
    whDay: "입고일자",
    buttonExcelItems: "엑셀다운로드 - 상품",
    deptDt: "출발일시",
    arrvDt: "도착일시",
    pod: "POD",
    wta: "WTA",
    wtc: "WTC",
    hisDt: "변경 일시",
    hisIp: "작업 IP",
    hawbActType: "작업 구분",
    recoveryDt: "복구 작업 일시",
    recoveryUserName: "복구 작업자",
    recoveryIp: "복구 작업 IP",

    commercial: {
      commercial: "Commercial",
      commercialTakeIn: "Commercial - 사입",
      printBL: "Print BL",
    },

    podDialog: {
      title: "POD",
      title2: "Track Shipments Detailed Results",
      trackingNumber: "Tracking Number",
      maskedCneeName: "받는 사람",
      date: "Date",
      time: "Time",
      area: "Area",
      detail: "Detail",
      postShipping: ">> 우체국 배송조회",
      dateKor: "날짜",
      timeKor: "시간",
      position: "현재 위치",
      traceStatus: "처리현황",
      detailDescription: "상세설명(배달결과)",
      receiver: "수령인",
      message1: "*표기된 시간은 각 서비스 지역의 현지 시간입니다.",
    },
  },
  en: {},
  zhHans: {},
};
