export default {
  ko: {
    type: "{text} 유형",
    selection: "{text} 선택",
    add: "{text} 등록",
    add2: "{text} 추가",
    edit: "{text} 수정",
    detail: "{text} 상세",
    yn: "{text} 여부",
    date: "{text}일",
    datetime: "{text}일시",
    dateRange: "{text} 기간",
    reset: "{text} 리셋",
    count: "{text} 회수",
    none: "{text} 없음",
    start: "{text} 시작",
    end: "{text} 종료",
    file: "{text} 파일",
    inquiry: "{text} 조회",
    kg: "{text} (Kg)",
    title: "{text} 제목",
    name: "{text} 이름",
    name2: "{text} 명",
    no: "{text} No.",
    greaterThan: "{text} 이상",
  },
  en: {},
  zhHans: {},
};
