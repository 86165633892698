<template>
  <v-app>
    <input type="text" style="width: 0; height: 0" />
    <component :is="currentLayout" v-if="isRouterLoaded">
      <router-view v-slot="{ Component }" :key="viewKey">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </component>
    <FooterComponent />
    <GlobalDialogConfirm />
    <input type="text" style="width: 0; height: 0" />
  </v-app>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import DefaultLayoutForAutoHeight from "@/layouts/DefaultLayoutForAutoHeight.vue";
import ErrorLayout from "@/layouts/ErrorLayout.vue";
import {computed} from "vue";
import GlobalDialogConfirm from "@/views/components/dialog/GlobalDialogConfirm.vue";
import { useRoute } from "vue-router";
import FooterComponent from "@/views/components/footer/FooterComponent.vue";
import SimpleLayout from "@/layouts/SimpleLayout.vue";
import { PRODUCT_TITLE } from "@/constants/envs";

const route = useRoute();
const isRouterLoaded = computed((): boolean => route.name !== null);
const currentLayout = computed(() => {
  if (route?.meta?.["layout"] === "default") {
    return DefaultLayout;
  } else if (route?.meta?.["layout"] === "defaultForAutoHeight") {
    return DefaultLayoutForAutoHeight;
  } else if (route?.meta?.["layout"] === "auth") {
    return AuthLayout;
  } else if (route?.meta?.["layout"] === "error") {
    return ErrorLayout;
  }
  return SimpleLayout;
});


// `viewKey`에 `refresh` 플래그를 포함하여 동일 경로 클릭 시 리렌더링 유도
const viewKey = computed(() => route.fullPath + (route.meta.refresh ? "-refresh" : ""));

document.title = window.sessionStorage.getItem("appTitle") ?? PRODUCT_TITLE;
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
