export default {
  ko: {
    orderDate: "등록(주문)일자",
    boxCnt: "포장개수(박스)",
    wt: "WT",
    receivingButton: "방법1: 입고확인",
    unitReceivingButton: "방법2: 건별입고",
    scanReceivingButton: "방법3: 스캔입고",
    printA4Button: "운송장출력(A4)",
    printLabelButton: "운송장출력(LABEL)",
    printLabelSideButton: "운송장출력(LABEL)-SIDE",
    printCJButton: "CJ운송장출력(LABEL)",
    printZPLButton: "ZPL",
    saveConfirm: "입고처리 하시겠습니까?",
    receivingButtonTitle: "입고확인",
    scanReceivingHistory: "스캔입고 이력",
    scanRst: "정상 / 에러메시지",
    scanHawbNo: "운송장번호 목록",
    receivingConfirm: "{value}건에 대하여 입고처리 진행하겠습니까?",
    removeConfirm: "{value}건의 운송장 삭제 하시겠습니까?",
    hawbDetailDialog: "운송장 상세",
    shipper: "발송업체",
    shipperName: "판매처 상호",
    shipperTel: "판매처 TEL",
    shipperAddr: "판매처 해외주소",
    customsSpcName: "특별통관 업체명",
    customsSpcNo: "특별통관 부호",
    salesBroker: "판매중개업체명(플랫폼)",
    salesBrokerNo: "판매중개업체등록부호",
    prchsAgent: "구매/배송대행업체명",
    prchsAgentNo: "구매/배송대행업체등록부호",
    osSeller: "해외판매자명(해외직접수입형)",
    osSellerNo: "해외판매자등록부호",
    userWta: "User실무게",
    userWtc: "User 부피무게",
    aciWta: "ACI 실무게",
    aciWtc: "ACI 부피무게",
    recoveryDate: "복구일자",
    recoveryDt: "복구일시",
    removeDate: "삭제일자",
    removeDt: "삭제일시",
    removeIp: "삭제 IP",
    recoverConfirm: "복구하시겠습니까?",
    infoExt: {
      formTitle: "기타정보",
    },
    remove: {
      userWta: "실무게",
      userWtc: "부피무게",
    },

    wta: "Actual WT",
    wtc: "Volume WT",
    invoiceWt: "Invoice WT",
    volGa: "가로",
    volSe: "세로",
    volHe: "높이",
    errYn: "Has Error",
    errorMessages: "Error Message",
    mawbNo: "Mawb No.",
    applyMawbNoButtonTitle: "입고확인 & Mawb Apply",
    applyMawbNoButtonConfirm: "입고후 마스터반영 처리 하시겠습니까?",

    deptDt: "출발일시",
    arrvDt: "도착일시",
    fltNo: "편명",

    applyWeightButtonTitle: "무게 적용",
    applyWeightButtonConfirm: "선택한 운송장의 무게정보 반영하시겠습니까?",
    applyWeightButtonConfirm2: "무게등록 및 입고 처리 하시겠습니까?",
    cancelWeightButtonTitle: "무게 적용 취소",
    cancelWeightButtonConfirm:
      "선택한 운송장의 무게정보 반영 취소하시겠습니까?",

    barcodeScan: "Bar Code Scan",
    printHawbNoMulti: "운송장 멀티 출력",
    barcodeScanResult: "Bar Code Scan 결과",
    checkHawbNo: "운송장번호 확인하십시오",

    printPostTypeC: "우체국 C형",
    printPostTypeCOrderNo: "우체국 C형(주문번호)",

    uni: {
      wta: "WT(A)",
      wtc: "WT(C)",
      aciWta: "ACI WT(A)",
      aciWtc: "ACI WT(C)",
      uniWta: "UNI WT(A)",
      uniWtc: "UNI WT(C)",
      applyButtonInvalid: "이미 무게적용된 운송장은 재 적용할수 없습니다.",
      applyButtonConfirm: "선택한 운송장의 무게정보 반영하시겠습니까?",
      cancelButtonConfirm: "선택한 운송장의 무게정보 반영 취소하시겠습니까?",
      deleteButtonInvalid: "이미 무게적용된 운송장은 삭제 할수 없습니다.",
      deleteButtonConfirm: "선택한 미적용 운송장의 무게정보 삭제하시겠습니까?",
    },

    uniDemetion: "UNI Demetion",
    appPer: "APP PER.",
    appYn: "무게적용여부",
    registeredHawbNo: "등록된 운송장 번호",

    icnFc: {
      applyButtonConfirm: "무게등록 및 입고 처리 하시겠습니까?",
    },
    per: "PER",
  },
  en: {},
  zhHans: {},
};
