export default {
  ko: {
    inspectionViewDialog: "운송장 상세 팝업",
    cneeName: "수하인명",
    cneeTel: "수하인 연락처",
    cneeAddr: "수하인 주소",
    buySite: "수하인 구매사이트",
    itemImgUrl: "상품 이미지",
    itemId: "Item ID",
    itemDetail: "상품명",
    trkNo: "트래킹 번호",
    siteIdNm: "사서함",
    siteId: "사서함번호",
    siteOrderNo: "사이트 주문번호",
    whStatus: "상세상태",
    whStatus2: "입고상태",
    orderNo: "고객 주문번호",
    itemQty: "발생수량",
    stockQty: "재고수량",
    stockNo: "재고 번호",
    whDate: "입고일자",
    orderDate: "등록(주문)일자",
    retInfo: "반품정보",
    stockStatus: "재고 상태",
    reqStatus: "요청 상태",
    rackNo: "Rack No.",
    trkCom: "택배사",
    inComment: "입고 메모",
    reqComment: "요청 메모",
    userWta: "WT(A)",
    userWtc: "WT(C)",
    reqOrderDate: "대체출고주문일자",
    reoQty: "대체출고수량",
    reoHawbNo: "대체출고 운송장번호",
    reoOrderNo: "대체출고 주문번호",
    reoCneeName: "대체출고 수하인",
    retStatus: "반품 상태",
    unitCnt: "상품 Qty.",
    inspectionResult: "검품결과",
    tabCountTextLine1: "[검색결과]",
    tabCountTextLine2: "@:inventory.outgoing.tabTitle 에 {text}건",
    tabCountTextLine3: "@:inventory.stock.tabTitle 에 {text}건",
    tabCountTextLine4: "@:inventory.return.tabTitle 에 {text}건",
    tabCountTextLine5: "검색되었습니다",
    returnDate: "반품일자",
    hawbNOrderNo: "운송장번호\n고객주문번호",
    RET_STATUS: {
      RG: "반품",
      GW: "폐기",
    },
    outgoing: {
      tabTitle: "출고대상 목록",
      outgoingButtonTitle: "출고 처리",
      outgoingDialog: {
        title: "출고/반품/재고 처리",
        OutInfo: "출고 정보",
        returnInfo: "반품 정보",
        memoInfo: "요청사항/출고 메모",
        OPT: {
          OUT: "출고",
          RET: "반품",
          STK: "재고",
        },
        warning: {
          opt: "작업 리스트내 작업선택(출고/반품/재고)을 선택해주세요.",
          optOutWithKumStatusIsClose:
            "이미 출고처리완료된 검품건의 상품은 다시 출고 선택 할수 없습니다.",
        },
      },
      memoDialog: {
        title: "요청메모/입고메모",
      },
    },
    stock: {
      tabTitle: "재고 목록",
      orderInfo: "원 주문정보",
      editRackNo: "Rack No. 수정",
      STOCK_QTY_FILTER: {
        MoreThanOne: "1 이상",
        IncludeZero: "0 포함",
      },
    },
    return: {
      tabTitle: "반품 목록",
      cusReturn: "반품Carrier",
      returnDate: "반품Shipping 일자",
      cusReturnTrkno: "반품 트래킹번호",
    },
  },
  en: {},
  zhHans: {},
};
