import { defineStore } from "pinia";
import { getNewToken, signOut } from "@/utils/commands";
import type { Drawer, JwtTokens } from "@/definitions/types";
import {
  BooleanType,
  type BooleanTypesString,
  MenuType,
} from "@/definitions/selections";
import type { User } from "@/views/system/user/models";
import { defaultUser } from "@/views/system/user/models";
import { DRAWERS } from "@/definitions/drawers";
import { cloneDeep } from "lodash-es";

type AdminInfo = {
  tokens: JwtTokens;
  admin: User;
};

export const useAdminStore = defineStore("admin", {
  state: (): AdminInfo => {
    return {
      admin: defaultUser(),
      tokens: {
        access: "",
        refresh: "",
      },
    };
  },
  getters: {
    loggedIn: (state: AdminInfo): boolean => {
      return (
        !!state.admin.adminId &&
        !!state.tokens.access &&
        !!localStorage.getItem("aci-eshop-admin-web-accessToken") &&
        !!localStorage.getItem("aci-eshop-admin-web-refreshToken")
      );
    },

    priceCrtYnFlag: (state: AdminInfo): boolean => {
      return state.admin?.priceCrtYn === BooleanType.TRUE;
    },
    priceMgmtYnFlag: (state: AdminInfo): boolean => {
      return state.admin?.priceMgmtYn === BooleanType.TRUE;
    },

    authLevel: (state: AdminInfo): number => {
      return +(state.admin?.mgmtCod?.basicCod ?? "999");
    },

    developerFlag: (state: AdminInfo): boolean => {
      return state.admin?.mgmtCod?.basicCod === "000";
    },
    supervisorFlag: (state: AdminInfo): boolean => {
      return state.admin?.supervisorYn === BooleanType.TRUE;
    },
    chgAfterCloseYn: (state: AdminInfo): BooleanTypesString => {
      return state.admin?.chgAfterCloseYn ?? BooleanType.FALSE;
    },

    adminId: (state: AdminInfo): string => {
      return state.admin.adminId;
    },

    adminStation: (state: AdminInfo): string => {
      return state.admin.station;
    },

    drawers: (state: AdminInfo): Drawer[] => {
      let _drawers: Drawer[];
      if (
        state.admin?.supervisorYn === BooleanType.TRUE ||
        state.admin?.mgmtCod?.basicCod === "000"
      ) {
        _drawers = cloneDeep(DRAWERS);
      } else {
        _drawers = DRAWERS.map((drawer) => ({
          ...drawer,
          children: drawer.children?.filter((child) =>
            state.admin.menuIdList?.includes(child.id),
          ),
        })).filter((drawer) => (drawer.children ?? []).length > 0);
      }
      if (
        _drawers.length > 0 &&
        (state.admin?.favMenuIdList ?? []).length > 0
      ) {
        const flatDrawer = _drawers.flatMap((drawer) => drawer.children);
        const favMenu: Drawer = {
          id: "FAV_MENU",
          type: MenuType.GROUP,
          icon: "mdi-star",
          children: state.admin.favMenuIdList
            .map((v) => flatDrawer.find((drawer) => drawer?.id === v)!)
            .filter((v) => v),
        };
        _drawers.unshift(favMenu);
      }
      return _drawers;
    },
  },
  actions: {
    clearAdmin(): void {
      window.localStorage.removeItem("aci-eshop-admin-web-accessToken");
      window.localStorage.removeItem("aci-eshop-admin-web-refreshToken");
      this.$reset();
    },

    async reIssueAccessToken(): Promise<void> {
      const newToken = await getNewToken();
      if (newToken) {
        this.saveTokens(newToken);
      }
    },

    saveTokens(tokens: JwtTokens): void {
      try {
        window.localStorage.setItem(
          "aci-eshop-admin-web-accessToken",
          tokens.access,
        );
        window.localStorage.setItem(
          "aci-eshop-admin-web-refreshToken",
          tokens.refresh,
        );
        this.tokens.access = tokens.access;
        this.tokens.refresh = tokens.refresh;
      } catch (e: unknown) {
        signOut().then();
      }
    },
  },
  persist: true,
});
