<template>
  <div class="d-flex flex-grow-1">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      class="elevation-1"
      floating
      light
    >
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="py-2 px-6">
          <v-img
            src="@/assets/images/aci_admin_logo.png"
            contain
            width="120"
            height="56.97"
          />
          <div
            class="text-overline text-blue-grey"
            style="color: #777777; text-transform: none !important"
          >
            {{ PRODUCT_VERSION }}&nbsp;&nbsp;{{ station }}&nbsp;-&nbsp;{{
              adminId
            }}
          </div>
        </div>
      </template>

      <!-- Navigation menu -->
      <v-list class="py-0" density="compact" nav>
        <NavMenu :drawers="drawers" />
      </v-list>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar :flat="false" app light>
      <v-card :flat="true" class="flex-grow-1 d-flex pa-0 ma-0">
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon
              size="48"
              style="margin-right: 12px"
              @click.stop="drawer = !drawer"
            />

            <v-spacer />

            <Select36pxOutlined
              v-model="language"
              :label="t('common.language')"
              :items="LANG_TYPES.filter((v) => v.useYn !== BooleanType.FALSE)"
            />
            <v-divider
              vertical
              length="12"
              inset
              color="#C8C8C8"
              style="margin-top: 18px; margin-right: 1rem; opacity: unset"
            />
            <Autocomplete36pxOutlined
              v-model="_station"
              :label="t('common.station')"
              :items="
                STATION.filter((v) => disabled || v.useYn !== BooleanType.FALSE)
              "
              :disabled="disabled"
              @blur="onBlur"
            />
            <v-divider
              vertical
              length="12"
              inset
              color="#C8C8C8"
              style="margin-top: 18px; margin-right: 1rem; opacity: unset"
            />

            <ToolbarAdmin />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container
        :fluid="true"
        class="fill-height"
        style="align-items: normal"
      >
        <v-layout style="height: 100%">
          <slot />
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts" setup>
import NavMenu from "@/views/components/navigation/NavMenu.vue";
import { computed, nextTick, onBeforeMount, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { BooleanType, LANG_TYPES } from "@/definitions/selections";
import ToolbarAdmin from "@/views/components/toolbar/ToolbarAdmin.vue";
import { PRODUCT_VERSION } from "@/constants/envs";
import { useConfigStore } from "@/stores/config";
import { useAdminStore } from "@/stores/admin";
import { computedAsync } from "@vueuse/core";
import { getStations } from "@/utils/apis";
import { useI18n } from "vue-i18n";
import Select36pxOutlined from "@/views/components/form/Select36pxOutlined.vue";
import Autocomplete36pxOutlined from "@/views/components/form/Autocomplete36pxOutlined.vue";
import { isEmpty } from "@/utils/rules";
import {
  getValidatedAccessToken,
  getValidatedRefreshToken,
} from "@/utils/commands";

const { t } = useI18n();

const drawer = ref(true);

const STATION = computedAsync(getStations, []);

const { language, station, stationName } = storeToRefs(useConfigStore());
const _station = ref<string | null>(station.value);

function onBlur() {
  if (isEmpty(_station.value)) {
    _station.value = station.value;
  }
}

watch(
  language,
  async (val, oldVal) => {
    localStorage.setItem("locale", val);
    if (oldVal !== undefined && oldVal !== val) {
      window.sessionStorage.setItem("appTitle", document.title);
      await nextTick();
      window.document.location.reload();
    }
  },
  { immediate: true },
);
watch(
  _station,
  async (val, oldVal) => {
    if (oldVal !== undefined && oldVal !== val && !isEmpty(val)) {
      station.value = val!;
      const _stationName =
        STATION.value?.find((v) => v.value === val)?.title ?? "";
      stationName.value =
        _stationName.indexOf("(") > -1
          ? (stationName.value = _stationName.split("(")[0])
          : (stationName.value = _stationName);
      window.localStorage.setItem("station", station.value);
      window.sessionStorage.setItem("appTitle", document.title);
      await nextTick();
      window.document.location.reload();
    }
  },
  { immediate: true },
);

const { drawers } = storeToRefs(useAdminStore());
const { admin, adminId } = useAdminStore();
const disabled = computed(() => admin.stationChooseYn === BooleanType.FALSE);

onBeforeMount(async () => {
  await getValidatedRefreshToken();
  await getValidatedAccessToken();
});
</script>
<style>
.v-list-item-title{
  font-size: 1rem !important;
}
</style>

