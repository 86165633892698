import i18n from "@/plugins/vue-i18n";
import type { DateTime } from "@/definitions/types";
import { isValidDateFormat } from "@/utils/formatter";
import dayjs from "dayjs";

export function isEmpty(val?: unknown | unknown[] | null) {
  if (Array.isArray(val)) {
    return val.length === 0;
  }
  return val === undefined || val === null || val === "";
}

export function isValidValue(value: unknown) {
  return value !== undefined && value !== null;
}

export function required(val: unknown | unknown[]): string | boolean {
  if (!isEmpty(val as string)) {
    return true;
  }
  return i18n.global.t("rules.required");
}

export const minLength =
  (min: number) =>
  (val: string | unknown[]): string | boolean => {
    if (isEmpty(val as string)) {
      return true;
    }
    if (Array.isArray(val)) {
      return (
        min <= val.length || i18n.global.t("rules.minArrayLength", { min })
      );
    }
    return min <= val.length || i18n.global.t("rules.minLength", { min });
  };
export const maxLength =
  (max: number) =>
  (val: string): string | boolean => {
    if (isEmpty(val as string)) {
      return true;
    }
    if (Array.isArray(val)) {
      return (
        val.length <= max || i18n.global.t("rules.maxArrayLength", { max })
      );
    }
    return val.length <= max || i18n.global.t("rules.maxLength", { max });
  };
export const minValueThan =
  (min: number) =>
  (val: string): string | boolean => {
    if (isEmpty(val as string)) {
      return true;
    }
    return min < +val || i18n.global.t("rules.minValueThan", { min });
  };

export const maxValueThan =
  (max: number) =>
  (val: string): string | boolean => {
    if (isEmpty(val as string)) {
      return true;
    }
    return +val < max || i18n.global.t("rules.maxValueThan", { max });
  };

export function isEmail(val: string): boolean | string {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return (
    isEmpty(val) || emailPattern.test(val) || i18n.global.t("rules.isEmail")
  );
}

export function isAlphanumeric(val: string): boolean | string {
  return (
    isEmpty(val) ||
    /^[a-zA-Z0-9]+$/.test(val) ||
    i18n.global.t("rules.isAlphanumeric")
  );
}
export function isDigits(val: string): boolean | string {
  return (
    isEmpty(val) || /^[0-9]+$/.test(val) || i18n.global.t("rules.isDigits")
  );
}
export function isNumeric(val: string): boolean | string {
  return (
    isEmpty(val) ||
    /^-?(\d+\.?\d*|\.\d+)$/.test(val) ||
    i18n.global.t("rules.isNumeric")
  );
}
export function isValidPassword(val: string): boolean | string {
  return (
    isEmpty(val) ||
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^*_]).{8,30}$/.test(val) ||
    i18n.global.t("rules.isValidPassword")
  );
}
export function is24Time(val: string): boolean | string {
  return (
    isEmpty(val) ||
    /^(?:[01][0-9]|[2][0-3])[0-5][0-9]$/.test(val) ||
    i18n.global.t("rules.is24Time")
  );
}

export const minDateValue =
  (minValue: DateTime) =>
  (val: string): string | boolean => {
    return (
      isEmpty(val) ||
      !isValidDateFormat(val) ||
      dayjs(val, "YYYY-MM-DD").isSameOrAfter(dayjs(minValue)) ||
      i18n.global.t("rules.minDateValue")
    );
  };
export function isNotExceed31(val: number): boolean | string {
    return (
        isEmpty(val) ||
        val <= 30 ||
        i18n.global.t("rules.isNotExceed31")
    );
}