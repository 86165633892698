export default {
  ko: {
    customer: "거래처",
    newCustomer: "신규 거래처",
    platformType: "플랫폼 구분",
    mallType: "상거래 유형",
    cusName: "거래처명",
    currency: "적용 Currency",
    invcStation: "청구 스테이션",
    tab: {
      basic: "기본정보",
      contract: "계약정보",
      price: "단가정보",
      attachment: "첨부파일",
      invoice: "Invoice",
      payment: "Payment",
      menu: "메뉴권한",
      history: "변경이력",
    },
    edi: {
      title: "EDI",
      siteUrl: "Site URL",
      seller: "Seller",
      wegDan: "무게 단위",
      naverStep: "글로벌윈도우 차수",
      salesBroker: "판매중개업체명(플랫폼)",
      prchsAgent: "구매(배송)대행업체명",
      osSeller: "해외판매자명(해외직접수입형)",
      salesBrokerNo: "판매중개업체등록부호(플랫폼)",
      prchsAgentNo: "구매(배송)대행업체등록부호",
      osSellerNo: "해외판매자등록부호(해외직접수입형)",
      customsSpcName: "특별통관업체명",
      customsSpcNo: "특별통관부호",
      returnDvlType: "반품정보",
      existedCustomerId: "이미 존재하는 거래처 ID 입니다.",
    },
    contract: {
      pageTitle: "계약서",
      contract: "계약",
      contractDate: "계약일",
      contractRange: "계약기간",
      guaranteeChrg: "보증보험 금액",
      guaranteeCurrency: "보증보험 금액(Currency)",
      guaranteeDateRange: "보증보험 기간",
      depositGuarantee: "신용한도액",
      depositGuaranteeDate: "신용한도액 입금일자",
      remainingContractDays: "남은 계약일",
      remainingWarrantyDays: "남은 보증일",
      contractFile: "계약서",
    },
    clearance: {
      pageTitle: "통관정보",
      customsDivi: "Duty",
      taxPay: "청구지",
      saNo: "검역 - 사업자번호",
      saMallNo: "검역 - 통신판매신고번호",
      saAddr: "검역 - 사업장주소",
      saCeo: "검역 - 대표자",
      foodChk: "식품 - 신고",
      foodNo: "식품 - 영업등록번호",
    },
    price: {
      pageTitle: "단가 적용",
      danOpt: "단가 적용",
      pricePlan: "Price Plan",
      danOptC: "개별 단가 적용",
      applyDate: "적용 시작일",
      changePrice: "요금추가",
      changePriceExcel: "요금추가_엑셀",
      filterDate: "조회 기준일",
      priceOth: "Other 요금",
      pricePlanDesc: "Freight Price Plan",
      freightUnitPrice: "Freight 구간별 단가",
    },
    invoice: {
      pageTitle: "Invoice",
      attnName: "담당자명",
      invAddr: "Invoice 주소",
      wtDeviation: "저울부피오차",
      wtDeviationHint: "*입고무게등록 메뉴에서 사용됩니다.",
      invcCycle: "Invoice Cycle",
      invcDueDate: "Due Date",
      useDeposit: "예치금 사용",
      idxDepositId: "예치금Lang User",
      invcCurrency: "Invoice Currency",
      invcNo: "Invoice No.",
      invcDt: "Invoice Date",
      terms: "Terms",
      hawbQty: "BL Qty.",
      invcAmt: "Invoice Amount",
      receivedAmt: "Received Amount",
      balance: "Balance",
      detailPopup: "상세조회",
      detail: "Invoice 상세",
      invcStation: "Invoice Station",
      optionORG: "원발생금액",
      optionCVT: "환율변환금액",
    },
    freight: {
      pageTitle: "Freight",
      frgMethod: "Freight 적용방식",
      formula: "Freight 계산식",
      formulaValue:
        "①@:customer.freightList.basicFee + ( (WT(C) - ②@:customer.freightList.dcWt)) / ③@:customer.freightList.unitWt) – ④@:customer.freightList.dcWtCnt) * ⑤@:customer.freightList.unitFee",
      wtVolumePer: "부피 적용율",
      invcVolumeApp: "부피무게로만 청구",
    },
    freightList: {
      pageTitle: "구간별 Freight",
      fromWt: "From WT",
      toWt: "To WT",
      basicFee: "건당 기본료",
      dcWt: "할인 WT",
      unitWt: "Per WT",
      dcWtCnt: "추가 할인 WT",
      unitFee: "구간 요금",
      pricePlan: "Price Plan",
      unitPrice: "구간별 단가",
      createPageTitle: "Freight - 요금변경",
      editPageTitle: "Freight - 요금수정",
      excelPageTitle: "Freight - 요금변경 - 엑셀",
    },
    otherList: {
      pageTitle: "Other 요금",
      priceItemId: "요금 항목",
      basicFee: "건당 요금",
      applyDate: "적용시작일",
      invoiceStation: "Invoice 스테이션",
      createPageTitle: "Other - 요금변경",
      editPageTitle: "Other - 요금수정",
    },
    signIn: {
      pageTitle: "로그인 정보",
      userMail: "패스워드 인증용 E-mail",
      authLevel: "사용자 권한",
    },
    workType: {
      pageTitle: "Work Type",
      shipperName: "Supplier Name",
      takbae: "택배사",
      optionKum: "검품",
      optionWtExtent: "무게오차",
      optionDelv: "배송대행업체",
      optionExportPage: "출고확정페이지제공",
      optionMakeBlno: "업체BLNO생성",
      optionBlExtent: "대역폭",
      takeIn: "사입",
      kumBl: "검품BL생성",
    },
    payment: {
      receivedDt: "Received Date",
      receivedKind: "Received Kind",
      receivedAmt: "Received Amount",
      invcApplyAmt: "Invoice 반영금액",
      invcNonApplyAmt: "Invoice 미 반영금액",
      applyInvcDt: "적용대상 Invoice Date",
      chequeNo: "Cheque No.",
      invcNo: "Invoice No.",
      invcDt: "Invoice Date",
      invcApplyPageTitle: "Invoice 반영내역",
      invcDetailPageTitle: "Received Details",
    },
    history: {
      cusActType: "작업 구분",
      refColumn: "참조 정보",
      befValue: "변경 전",
      aftValue: "변경 후",
    },
    coupangPromotion: {
      cusStatus: "승인 상태",
      cusnameHan: "거래처명 (한글)",
      cusaddrHan: "주소 (한글)",
      attntel: "담당자 연락처",
      attnemail: "담당자 이메일",
      cusname: "거래처명 (영문)",
      cusaddr: "주소 (영문)",
      cussiteurl: "Site",
      waitCnt: "승인대기 건 수: "
    },
    hawbRange: {
      fromHawbNo: "운송장번호 From",
      toHawbNo: "운송장번호 To",
      userIdNotFound: "미등록 거래처",
    },
  },
  en: {},
  zhHans: {},
};
