export default {
  ko: {
    required: "입력 필수입니다!",
    required1: "하나 이상의 데이터를 등록해주세요!",
    requiredFile: "파일을 등록해주세요!",
    minLength: "{min}자 이상 입력해야 합니다!",
    maxLength: "{max}자 까지 입력해야 합니다!",
    minValueThan: "{min}보다 큰 값을 입력해야 합니다!",
    maxValueThan: "{max}보다 작은 값을 입력해야 합니다!",
    minArrayLength: "{min}개 이상 등록 하세요!",
    maxArrayLength: "{max}개 이하로 등록 가능합니다!",
    isEmail: "올바르지 않은 이메일 형식입니다!",
    isAlphanumeric: "영문, 숫자만 입력 가능합니다!",
    isDigits: "숫자만 입력 가능합니다!",
    isNotExceed31: "31일 이상은 입력할 수 없습니다.",
    isNumeric: "숫자와 음수부호(-), 소수점(.) 입력만 가능합니다!",
    isValidPassword:
      "영문, 숫자, 특수문자(!{'@'}#{'$'}%^*_)를 혼합하여 8자리~30자리 문자로 구성해야 합니다!",
    is24Time: "시간은 0000 ~ 2359 범위의 시간 값이어야 합니다!",
    minDateValue: "날짜의 최소값이 잘못되었습니다!",
    imageWidthHeight: "이미지의 가로, 세로 크기가 잘못되었습니다!",
  },
  en: {},
  zhHans: {},
};
