export default {
  ko: {
    confCod: "설정코드",
    confDesc: "설정내용",
    confVal1: "설정값1",
    confVal2: "설정값2",
    confVal3: "설정값3",
    confVal4: "설정값4",
    confVal5: "설정값5",
    confGroup: "설정구분",

    user: {
      authLevel: "사용자그룹",
      stationChooseAuth: "스테이션 선택 권한",
      priceCrtAuth: "단가 생성 권한",
      priceMgmtAuth: "단가 수정 권한",
      chgAfterCloseAuth: "MAWB 마감 후 수정권한",
      lastLogin: "최종 접속",
      resetLastLoginDt: "휴먼계정/최종접속일 리셋",
      pwdExpiry: "패스워드 만료",
      loginFail: "로그인 실패",
      menuAuth: "메뉴 권한",
      existedAdminId: "이미 존재하는 사용자 ID 입니다.",
      menuAuthDialogTitle: "사용자 메뉴권한 설정",
    },
    code: {
      code: "코드",
      groupCod: "그룹코드",
      basicCod: "기초코드",
      langType: "언어유형",
      codDesc: "코드설명",
    },
    myInfo: {
      title: "My Info.",
      favoriteMenuTitle: "즐겨찾기 메뉴 설정",
    },
    setting: {
      dialogTitle: "시스템 설정 정보",
    },
    blExcel: {
      addButtonTitle: "양식 추가",
      copyButtonTitle: "Copy +",
      blExcelIdx: "양식코드",
      blExcelNm: "엑셀양식명",
      station: "스테이션",
      blType: "양식구분",
      codDesc: "엑셀필드",
      columnTitle: "엑셀-헤더명칭",
      columnNm: "운송장항목",
      fixValue: "고정값",
      mandatoryYn: "필수여부",
      columnDesc: "운송장항목",
      excelColumn: "엑셀필드",
      editDialog: {
        title: "양식",
        duplicatedExcelColumns: "엑셀필드 값은 중복으로 선택할 수 없습니다.",
      },
      tab: {
        info: {
          title: "양식 정보",
          editButtonTitle: "설정 변경",
        },
        history: {
          title: "변경 이력",
        },
      },
    },
    station: {
      station: "스테이션코드",
      stationName: "스테이션명",
      stationCurrency: "Currency",
      stationComment: "Comment",
      stationAddr: "주소",
      stationTel: "전화번호",
      stationFax: "FAX",
      depositBank: "Deposit Bank",
      depositName: "Deposit Name",
      depositAccount: "Deposit Account",
      stationTwoCode: "2Digit Code",
      stationCity: "City Code",
      invcBankInfo: "인보이스 입금계좌정보",
      addButtonTitle: "스테이션 추가",
      tab: {
        info: {
          title: "기본정보",
        },
        attachment: {
          title: "첨부파일",
        },
      },
    },
  },
  en: {},
  zhHans: {},
};
