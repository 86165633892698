export default {
  ko: {
    save: "저장",
    reload: "검색",
    reset: "초기화",
    add: "등록",
    edit: "수정",
    remove: "삭제",
    close: "닫기",
    ok: "확인",
    cancel: "취소",
    submit: "제출",
    logout: "로그아웃",
    excel: "엑셀다운로드",
    sample: "샘플파일 다운로드",
    search: "검색",
    recover: "복구",
    apply: "적용",
    saveChanging: "변경 저장",
    fetchCode: "코드데이터 갱신",
  },
  en: {
    save: "Save",
    reload: "Reload",
    reset: "Reset",
    add: "Add",
  },
  zhHans: {
    save: "拯救",
    reload: "刷新",
    reset: "初始化",
    add: "添加",
  },
};
