export default {
  ko: {
    applyDate: "적용일자",
    exchange: "환율",
    addExchangeRateButton: "환율등록-개별",
    addExchangeRateExcelButton: "환율등록-엑셀",
    basisCurrency: "기준 Currency",
    targetCurrency: "대상 Currency",
    exchgRate: "Exchange Rate",
    addExchangeRate: "환율등록",
    confirmDuplicationCreate:
      "이미 등록된 환율정보입니다. 입력하신 환율로 적용하시겠습니까?",
    confirmExcelUpload:
      "환율정보 등록하시겠습니까? (중복오류, 이미등록된 환율정보는 제외하고 저장됩니다.)",
    duplicatedExchangeRate: "중복오류",
    alreadyRegisteredExchangeRate: "이미 등록된 환율정보",
    chkDate: "적용일자",
    invcDt: "Invoice 일자",
    chrgChkDesc: "상세구분",
    mawbDesc: "MAWB No.",
    invcApplyYn: "Invoice 적용여부",
    invcNo: "Invoice No.",
    confirmDt: "기준일자",
    invoiceDt: "Invoice 일자",
    priceItem: "요금항목",
    priceItemCd: "요금항목코드",
    adjustFee: "금액",
    exchgRate2: "적용 환율",
    convChrg: "환율적용 요금",
    currency: "적용 Currency",
    invcStation: "Invoice Station",
    frgChrg: "Freight",
    otherChrg: "Other Charges",
    totInvcAmt: "Total Invoice",
    totReceivedAmt: "Total Received",
    totBalanceAmt: "Total Balance",
    cusName: "Company",
    payment: "Payment",
    receivedDt: "Received Date",
    userStation: "User Station",

    ftaFoodQuarantineRegistration: {
      ftaFoodQuarantine: "FTA/식품/검역",
      addButtonTitle: "FTA/식품/검역 등록-개별",
      addExcelButtonTitle: "FTA/식품/검역 등록-엑셀",
      confirmExcelUpload:
        "FTA/식품/검역 등록하시겠습니까? (중복오류, 이미등록된 FTA/식품/검역은 제외하고 저장됩니다.)",
      CHRG_CHK_DESC: {
        ANIMAL: "동물",
        PLANT: "식물",
        FOOD: "식품",
        FTA: "FTA",
      },
    },

    etcCharge: {
      addButtonTitle: "ETC요금 등록-개별",
      addExcelButtonTitle: "ETC요금 등록-엑셀",
      priceInfo: "요금정보",
      excel: {
        priceItemCd: "요금코드(약어)",
        saveConfirm:
          "ETC요금 정보 등록하시겠습니까?  (오류내역은 제외하고 저장됩니다.)",
      },
      removeConfirm: "선택한 ETC요금  {value}건 삭제하시겠습니까?",
    },

    priceApply: {
      title: "Price Apply",
      priceApplyListTitle: "Price 미적용 목록",
      hblQty: "미적용 BL Qty",
      invoiceTargetListTitle: "인보이스 대상 목록",
      invcTerms: "Invoice Terms.",
      invcCycle: "Invoice Cycle",
      invcWt: "Invoice WT",
      nonQty: "단가 미적용 건수",
      nonCln: "통관수수료 미적용건수",
      nonChg: "미적용 기타요금",
      priceApplyHistory: "Price Apply 작업이력",
      requiredFilter: "Invoice Terms., Invoice Cycle 는 필수 입력값입니다.",
      postingChk: "Posting / Unposting",
      target: {
        hblQty: "BL Qty",
      },
    },

    pricePlan: {
      addButtonTitle: "Price Plan 추가",
      priceItemButtonTitle: "요금항목",
      pricePlan: "Price Plan",
      priceItemCd: "요금코드",
      priceItemDesc: "설명",
      priceItemKor: "설명-KOR",
      freightUpdDt: "Freight 요금 수정일시",
      priceFreight: {
        popupTitle: "Freight - 수정",
        applyDate: "변경 시작일",
        createConfirm: "Freight요금정보 등록하시겠습니까?",
        excel: {
          title: "Freight요금변경-엑셀 팝업",
        },
      },
    },

    arControl: {
      totInvcAmt: "Invoice Amount",
      totReceivedAmt: "Received Amount",
      balance: "Balance",
      unpaidInvoice: "미납 Invoice",
      received: "Received",
      payCostAmt: "기타잡비",
    },

    arConfirm: {
      confirm: "Confirm",
      cancelConfirm: "Cancel confirm",
      confirmYn: "Confirm 여부",
      confirmDt: "Confirm Date",
      confirmUserName: "Confirm 작업자",
      invcNonApplyAmt: "Invoice 미 반영금액",
      receivedDetails: "Received Details",
      amount: "Amount",
      invoiceReflectionDetails: "Invoice 반영내역",
      confirmYnError: "이미 Confirm처리된 내역을 또다시Confirm 할수 없습니다.",
      confirmButtonConfirm:
        "선택한 Payment내역을 Confirm하고 인보이스에 반영하시겠습니까?",
      cancelConfirmYnError: "Confirm 처리된내역만 Cancel 할수있습니다.",
      cancelConfirmButtonConfirm:
        "선택한 Payment  Confirm을 취소 하시겠습니까?",
      removeWarning: "Confirm처리된 내역은 삭제 할수 없습니다.",
      removeButtonConfirm: "선택한 Payment내역을 정말로 삭제하시겠습니까?",
    },

    arReport: {
      confirmed: "Confirmed",
      notConfirmed: "Not-Confirmed",
      currency: "Currency",
      receivedKind: "Received Kind",
      receivedAmt: "Received Amount",
      payAmt: "Payment Amount",
      invcApplyAmt: "Invoice 반영금액",
      invcNonApplyAmt: "Invoice 미 반영금액",
      removeWarning: "Confirm처리된 내역은 삭제 할수 없습니다.",
      removeButtonConfirm: "선택한 Payment내역을 정말로 삭제하시겠습니까?",
      incidentalCostDialog: {
        title: "Payment-부대비용상세",
      },
      invoiceApplyDialog: {
        title: "Payment-Invoice반영상세",
      },
    },

    unpaidCount: "미납횟수",
    unpaidTotalAmt: "총미납액",
    totCnt: "미납횟수",
    totBalance: "총미납액",
    totMonth01: "1개월",
    totMonth02: "2개월",
    totMonth03: "3개월",
    totMonthOver: "3개월 이상",

    tab: {
      list: {
        title: "Invoice List",
        unPosting: "Un-Posting",
        priceApplyHistory: "Price Apply 작업이력",
        invoiceExcel: "Invoice 엑셀",
        invoicePrint: "Invoice Print",
        cover: "수출면장 Cover",
        blList: "수출면장 BL List",
        userStation: "User Station",
        pricePlanDesc: "Reference Price Plan",
        crtDt: "Posting Date Time",
        unPostingWarning: "수납 반영된 인보이스는 Un-Posting 할수 없습니다.",
        unPostingConfirm: "정말로 인보이스 내역 취소 하시겠습니까?",
        excelDownloadLimit: "한번에 10개까지만 다운로드 가능합니다.",
      },
      shipping: {
        title: "발송내역",
        deptDt: "Departure Date",
        excelDetailButton: "상세-엑셀",
        cusName: "Customer",
        blQty: "BL Qty.",
        totInvcWt: "Invc WT",
        totInvcWtKg: "Invc WT (KG)",
        invcStation: "Freight 청구스테이션",
        totBoxQty: "포장박스 Qty.",
      },
      monthlyShipping: {
        title: "월별 발송내역",
        deptMonth: "Departure Month",
      },
      statement: {
        title: "Statement",
      },
    },
  },
  en: {},
  zhHans: {},
};
