import type { IdxCreatedUpdatedIdDate } from "@/definitions/models";
import type { BooleanTypesString } from "@/definitions/selections";
import { BooleanType } from "@/definitions/selections";
import type { Code } from "@/views/system/code/models";
import type { DateTime } from "@/definitions/types";

interface UserBase extends IdxCreatedUpdatedIdDate {
  adminId: string;
  adminName: string;
  adminEmail?: string;
  adminTel?: string;
  useYn: BooleanTypesString;
  authLevel: string;
  stationChooseYn: BooleanTypesString;
  priceCrtYn: BooleanTypesString;
  priceMgmtYn: BooleanTypesString;
  chgAfterCloseYn: BooleanTypesString;
  adminComment?: string;
  menuIdList: string[] | null;
  favMenuIdList: string[] | null;
}
function defaultUserBase(): UserBase {
  return {
    adminId: "",
    adminName: "",
    useYn: BooleanType.TRUE,
    stationChooseYn: BooleanType.FALSE,
    priceCrtYn: BooleanType.FALSE,
    priceMgmtYn: BooleanType.FALSE,
    chgAfterCloseYn: BooleanType.FALSE,
    authLevel: "",
    menuIdList: [],
    favMenuIdList: [],
  };
}

export interface User extends UserBase {
  station: string;
  mgmtCod?: Code;
  supervisorYn: BooleanTypesString;
  lastLoginDt: DateTime;
  menuIdList: string[];
  favMenuIdList: string[];
  loginFailCnt: number;
  pwdExpiryDt: DateTime;
  pwdUpdDt: DateTime;
}

export function defaultUser(): User {
  return {
    ...defaultUserBase(),
    station: "",
    supervisorYn: BooleanType.FALSE,
    lastLoginDt: null,
    menuIdList: [],
    favMenuIdList: [],
    loginFailCnt: 0,
    pwdExpiryDt: null,
    pwdUpdDt: null,
  };
}

export interface UserCreate extends UserBase {
  adminPwd?: string;

  lastLoginDt?: DateTime;
  loginFailCnt?: number;
  pwdUpdDt?: DateTime;
  pwdExpiryDt?: DateTime;
  lastLoginReset?: boolean;
  failCountReset?: boolean;
  pwdUpdReset?: boolean;
}

export function defaultUserCreate(): UserCreate {
  return {
    ...defaultUserBase(),
    authLevel: "",
  };
}
