export default {
  ko: {
    mawb: "MAWB",
    mawbList: "MAWB LIST",
    mawbNo: "MAWB No.",
    deptDt: "출발일시",
    deptDay: "출발일",
    deptTime: "출발시간",
    arrvDt: "도착일시",
    arrvDay: "도착일",
    arrvTime: "도착시간",
    fltNo: "편명",
    mawbCreateButtonTitle: "MAWB 생성",
    mawbUpdateButtonTitle: "MAWB 수정",
    mawbCloseButtonTitle: "MAWB 마감",
    mawbApplyAndScanButtonTitle: "MAWB Apply -스캔",
    blQty: "BL\nCNT",
    totWta: "WT(A)",
    totWtc: "WT(C)",
    totInvc: "Invoice\nWT",
    detail: "MAWB Detail",
    statistics: "MAWB Statistics",
    history: "MAWB History",
    mrn: "MRN",
    hawbDt: "입고일시",
    wegDan: "무게 단위",

    valex: "Valex",
    boxNo: "BOX No.",

    changeMawbNoConfirm: "정말로 MAWB No. 수정하시겠습니까?",
    closeMawbNoConfirm: "선택한 MAWB 마감하시겠습니까?",

    cusName: "Company",
    wtVolumePer: "Volume per(%)",
    invcVolumeApp: "부피청구",
    wtInvc: "Invoice WT",

    scan: {
      dialogTitle: "MAWB Apply - Scan",
    },

    tab: {
      outgoingHawb: {
        title: "출고대상 운송장",
        applyButtonTitle: "MAWB Apply",
        receivingCancelButtonTitle: "입고취소",
        applyKGLMAWBButtonTitle: "KGL MAWB Apply",
        applyHint: "선택한 운송장 {value}건 MAWB 적용하시겠습니까?",
        cancelButtonConfirm: "선택한 운송장 {value}건 입고취소 하시겠습니까?",
        removeButtonConfirm:
          "선택한 운송장 {value}건 정말로 삭제 하시겠습니까?",
      },
      mawbDetail: {
        title: "MAWB Detail",
        changeWeight: "무게 변경",
        changeMawb: "MAWB 변경",
        changeMawbTextarea: "MAWB 변경 - Text Area",
        cancelOutgoing: "출고 취소",
        workType: "작업 선택",
        optAll: "WT(A) & WT(C) 변경",
        optWta: "WT(A) 변경",
        optWtc: "WT(C) 변경",
        changeWta: "변경할 WT(A)",
        changeWtc: "변경할 WT(C)",
        changeWeightConfirm: "{value}건의 운송장 무게정보 변경하시겠습니까?",
        changeMawbNo: "변경할 MAWB No.",
        changeHawbNoListLength: "변경할 운송장 선택 수",
        changeHawbNo: "변경할 운송장",
        changeMawbConfirm: "선택한 운송장의 MAWB 변경하시겠습니까?",
        cancelButtonConfirm: "선택한 운송장 {value}개 출고취소 하시겠습니까?",
      },
      mawbStatistics: {
        title: "MAWB Statistics",
        blQty: "운송장 Qty.",
        totWta: "Actual Weight(kg)",
        totWtc: "Volume Weight(kg)",
        totInvc: "Invoice Weight(kg)",
      },
      mawbHistory: {
        title: "MAWB 변경이력",
        jobType: "작업 구분",
        note: "비고 / 적용 운송장",
      },
    },

    registerBusinessWeightByMawb: {
      registerButtonTitle: "업체무게 일괄등록",
      totWta: "WT(A) Total",
      totWtc: "WT(C) Total ",
      wegDan: "무게 단위/DB",
      downloadExcelWarningInvalidDeptDt:
        "출발일자 조회기간을 지정하여 엑셀다운로드 하시기바랍니다.",
      saveChangingConfirm: "업체무게정보 적용하시겠습니까?",
      registerDialog: {
        dialogHint: "NET 방식 업체별 무게정보 일괄등록 하시겠습니까?",
        registerButtonTitle: "일괄등록",
        invalidDeptDtRange: "기간 일수가 최대 1개월을 넘지 않아야 합니다.",
      },
    },
  },
  en: {},
  zhHans: {},
};
