export default {
  ko: {
    FAV_MENU: "즐겨찾기",

    INSPECTION: "검품",
    INSPECTION_LIST: "검품목록",
    INSPECTION_NEW_LIST: "검품목록 NEW",
    INSPECTION_RECEIVING: "검품입고",
    INSPECTION_RECEIVING_NEW: "검품입고 NEW",

    RECEIVING_OPERATIONS: "입고작업",
    RECEIVING_OPERATIONS_BARCODE_SCAN: "바코드 Scan",
    RECEIVING_OPERATIONS_WEIGHT_REGISTRATION: "입고 무게 등록",
    RECEIVING_OPERATIONS_MULTI_PRINT_WAYBILL: "운송장 멀티출력",
    RECEIVING_OPERATIONS_UNI_WEIGHT_REGISTRATION: "UNI 무게등록",
    RECEIVING_OPERATIONS_ICN_FC_WEIGHT_REGISTRATION: "ICN FC 무게등록",
    RECEIVING_OPERATIONS_REMOVE_WAYBILL: "삭제운송장",

    MAWB: "MAWB",
    MAWB_APPLY: "MAWB 적용",
    MAWB_APPLY_NEW: "MAWB 적용 NEW",
    MAWB_REGISTER_BUSINESS_WEIGHT_BY_MAWB: "MAWB별 업체무게등록",

    SHIPPING: "발송내역관리",
    SHIPPING_LIST: "발송목록",
    SHIPPING_INTEGRATED_SHIPPING_LIST: "통합발송목록",
    SHIPPING_COMMERCIAL: "Commercial/BL",

    INVENTORY: "재고관리",
    INVENTORY_LIST: "재고목록",

    PURCHASE: "사입관리",
    PURCHASE_TAKE_IN_CODE: "상품코드관리",
    PURCHASE_TAKE_IN_ITEM: "상품관리",
    PURCHASE_TAKE_IN_SHIPMENT_PROCESS: "발송처리",
    PURCHASE_INVENTORY_CHECK: "재고체크",

    INVOICE: "Invoice",
    INVOICE_PRICE_PLAN: "요금정보관리",
    INVOICE_DESTINATION_SHIPPING_COST: "도착지배송비관리",
    INVOICE_CURRENCY: "환율관리",
    INVOICE_FTA_FOOD_QUARANTINE_REGISTRATION: "FTA/식품/검역 등록",
    INVOICE_ETC_CHARGE: "ETC 요금 등록",
    INVOICE_PRICE_APPLY: "Price Apply",
    INVOICE_INVOICE: "Invoice",
    INVOICE_CONTROL: "A/R Control",
    INVOICE_CONFIRM: "A/R Confirm",
    INVOICE_REPORT: "A/R Report",
    INVOICE_AGE: "Aging Report",

    CUSTOMER: "거래처관리",
    CUSTOMER_INFO: "거래처정보",
    CUSTOMER_PRICE: "거래처 단가리스트",
    CUSTOMER_PROMOTION: "쿠팡프로모션 입점업체",
    CUSTOMER_CONTRACT: "계약서",
    CUSTOMER_HAWB_RANGE: "거래처별 운송장번호 대역",

    SYSTEM: "System",
    SYSTEM_USER: "사용자관리",
    SYSTEM_CODE: "코드관리",
    SYSTEM_MENU: "시스템설정관리",
    SYSTEM_BL_EXCEL: "운송장 양식관리",
    SYSTEM_STATION: "스테이션정보",
    SYSTEM_MY_INFO: "My Info.",

    BOARD: "게시판",
    BOARD_QNA: "QnA",
    BOARD_NOTICE: "공지사항",
  },
  en: {},
  zhHans: {},
};
