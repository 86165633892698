<template>
  <div @click="closeContextMenu">
    <!-- 하나의 전역 contextMenu -->
    <div
        v-if="contextMenu.visible"
        :style="{ top: `${contextMenu.y - 100}px`, left: `${contextMenu.x - 50}px` }"
        class="context-menu"
    >
      <ul>
        <li class="d-flex">
          <span @click="openInNewTab">새 탭에서 열기</span>
          <v-icon class="pt-1 ml-2" size="small" icon="mdi-close" @click="closeContextMenu" />
      </li>
      </ul>
    </div>
    <NavMenuItem
    v-for="drawer in drawers"
    :key="drawer.id"
    :drawer="drawer"
    @show-context-menu="showContextMenu"
    >
      <NavMenu :drawers="drawer.children" :depth="depth + 1" />
    </NavMenuItem>

  </div>
</template>

<script setup lang="ts">
import NavMenu from "@/views/components/navigation/NavMenu.vue";
import NavMenuItem from "@/views/components/navigation/NavMenuItem.vue";
import type { Drawer } from "@/definitions/types";
import {ref} from "vue";

withDefaults(
  defineProps<{
    drawers?: Drawer[] | null;
    depth?: number;
  }>(),
  { drawers: () => [], depth: 0 },
);

// 전역 contextMenu 상태 관리
const contextMenu = ref({
  visible: false,
  x: 0,
  y: 0,
  currentDrawer: {} as Drawer, // 클릭된 메뉴의 정보를 저장
});

function showContextMenu(event: MouseEvent, drawer: Drawer): void {
  event.preventDefault();
  contextMenu.value.visible = true;
  contextMenu.value.x = event.clientX;
  contextMenu.value.y = event.clientY;

  contextMenu.value.currentDrawer = drawer; // 클릭된 메뉴의 정보 저장
}

function closeContextMenu(): void {
  contextMenu.value.visible = false;
}

function openInNewTab(): void {
  if (contextMenu.value.currentDrawer) {
    window.open(contextMenu.value.currentDrawer.url, '_blank');
  }
  closeContextMenu();
}
</script>
<style scoped>
.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 4px;
}

.context-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.context-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu ul li:hover {
  background-color: #eee;
}
</style>