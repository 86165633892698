export default {
  ko: {
    all: "전체",
    orderNo: "주문번호",
    trkNo:"택배번호",
    receivingDuty:"입고작업",
    cneeName: "수하인명",
    cneeTel: "수하인 연락처",
    cneeAddr: "수하인 주소",
    buySite: "수하인 구매사이트",
    expectWt: "예상 무게",
    aciWt: "ACI 무게",
    itemImgUrl: "상품 이미지",
    hsCode: "HS CODE",
    itemBrand: "상품 브랜드",
    itemId: "Item ID",
    itemDetail: "상품명",
    siteId: "사서함번호",
    siteOrderNo: "사이트 주문번호",
    nno: "관리번호",
    dlvComment: "배송 메모",
    mallType: "상거래 유형",
    customsNo: "개인통관고유부호(PCCC)",
    cneeHp: "휴대폰번호",
    orderDate: "등록(주문)일자",
    attnCode: "담당자",
    makeCountry: "제조국",
    makeCompany: "제조회사명",
    trkCom: "택배사",
    foodDivi: "식품구분",
    itemColor: "상품 색상",
    itemSize: "상품 사이즈",
    itemUrl: "상품 URL",
    whComment: "요청 메모",
    kumStatus: {
      ALL: "검수전체(출고포함) 조회",
      NOT: "미출고 조회",
    },
    receiving: {
      orderDate: "등록(주문)일자",
      orderNo: "고객 주문번호",
      stockStatus: "재고상태",
      whStatus: "상세상태",
      popupTitle: "검품입고-상세",
      shipperName: "발송업체명",
      shipperTel: "발송업체 Tel",
      shipperAddr: "발송업체 주소",
      stockResult: {
        title: "검수결과",
        N: "정상입고",
        F: "검수불합격",
      },
      inputComment: "검품결과",
      unitValue: "Net Value",
      trkCom: "택배사",
      whMerge: "취합대기",
      confirmReceiptButton: "입고확인",
      requestReceiptConfirm: "취합대기 상품과 함께 출고처리 하시겠습니까?",
      requestWhMergeConfirm: "취합대기 상태로 저장하시겠습니까?",
      cancelButtonTitle: "입고 취소",
      cancelButtonConfirm: "선택한 주문정보 입고취소 하시겠습니까?",
      outForm: {
        boxCnt: "BOX 개수",
        wta: "Actual WT",
        expectWt: "고객예상무게",
        optionWtExtent: "무게오차범위",
        chargeableWt: "Chargeable WT",
        volGa: "가로 width",
        volSe: "세로 length",
        volHe: "높이 height",
        totalVolume: "Total Volume",
        confirmOutButton: "출고 처리",
      },
    },
    addDialog: {
      title: "운송장 상세",
      editTitle: "상세조회/수정",
      consignee: {
        title: "수하인",
        cneeTel: "유선전화",
        cneeZip: "우편번호(5자리)",
        cneeAddr2: "상세주소",
      },
      orderInfo: {
        title: "주문정보",
        orderNo: "고객주문번호",
        getBy: "용도구분",
        realBuySite: "판매자 구매사이트 (구매대행업자)",
      },
      orderItemList: {
        title: "상품정보",
        unitValue: "Net Value (USD)",
        hsCodeButtonTitle: "HS CODE",
      },
      whReqList: {
        title: "요청사항",
        memo: "메모 내용",
      },
    },
  },
  en: {},
  zhHans: {},
};
